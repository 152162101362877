interface AppConfig {
  WEATHER_FLAG: string;
  FORM_URL: string;
  HOME_URL: string;
  CAR_URL: string;
  HOME_LOGIN_URL: string;
  CAR_LOGIN_URL: string;
  CAR_RETRIEVE_URL: string;
  DOCUMENTS_URL: string;
  FONTS_CDN_URL: string;
  PHONES_CDN_URL: string;
  TIMES_CDN_URL: string;
  HELP_CENTRE_URL: string;
  ENVIRONMENT_NAME: string;
  PET_URL: string;
  PET_RETRIEVE_URL: string;
  CAREERS_URL: string;
  HYPERTUNE_TOKEN: string;
}

const getConfig = () => {
  const config = {
    WEATHER_FLAG: process.env.GATSBY_WEATHER_FLAG,
    FORM_URL: process.env.GATSBY_FORM_URL,
    HOME_URL: process.env.GATSBY_HOME_URL,
    CAR_URL: process.env.GATSBY_CAR_URL,
    HOME_LOGIN_URL: process.env.GATSBY_HOME_LOGIN_URL,
    CAR_LOGIN_URL: process.env.GATSBY_CAR_LOGIN_URL,
    CAR_RETRIEVE_URL: process.env.GATSBY_CAR_RETRIEVE_URL,
    DOCUMENTS_URL: process.env.GATSBY_DOCUMENTS_URL,
    FONTS_CDN_URL: process.env.GATSBY_FONTS_CDN_URL,
    PHONES_CDN_URL: process.env.GATSBY_PHONES_CDN_URL,
    TIMES_CDN_URL: process.env.GATSBY_TIMES_CDN_URL,
    HELP_CENTRE_URL: process.env.GATSBY_HELP_CENTRE_URL,
    ENVIRONMENT_NAME: process.env.GATSBY_ENVIRONMENT_NAME,
    PET_URL: process.env.GATSBY_PET_URL,
    PET_RETRIEVE_URL: process.env.GATSBY_PET_RETRIEVE_URL,
    CAREERS_URL: process.env.GATSBY_CAREERS_URL,
    HYPERTUNE_TOKEN: process.env.GATSBY_HYPERTUNE_TOKEN,
  };

  for (const [key, value] of Object.entries(config)) {
    if (value === undefined) {
      throw new Error(`Missing environment variable: ${key}`);
    }
  }
  return config as AppConfig;
};

export default getConfig();
