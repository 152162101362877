import React from 'react';

import config from '../config';
import type { Environment } from '../generated/hypertune';
import { HypertuneProvider } from '../generated/hypertune.react';
import getEnvironment from '../helpers/getEnvironment';

export default function AppHypertuneProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <HypertuneProvider
      createSourceOptions={{
        token: config.HYPERTUNE_TOKEN,
        /**
         * Disables the default polling behaviour.
         * Flags are only fetched when the component loads.
         */
        shouldRefreshInitData: false,
      }}
      rootArgs={{
        context: {
          environment: getEnvironment(config.ENVIRONMENT_NAME) as Environment,
          // @ts-ignore
          user: { email: '' },
        },
      }}
    >
      {children}
    </HypertuneProvider>
  );
}
