/* eslint-disable */

import * as sdk from 'hypertune';

export const queryId = '257d3c05-e0eb-5343-8073-e14a5ac209e0';

export const query: sdk.Query<sdk.ObjectValueWithVariables> = {
  variableDefinitions: {},
  fragmentDefinitions: {
    TempIssuesBanner: {
      type: 'InlineFragment',
      objectTypeName: 'TempIssuesBanner',
      selection: {
        isEnabled: { fieldArguments: {}, fieldQuery: null },
        messageToShow: { fieldArguments: {}, fieldQuery: null },
        displayUrls: { fieldArguments: {}, fieldQuery: null },
      },
    },
  },
  fieldQuery: {
    Query: {
      type: 'InlineFragment',
      objectTypeName: 'Query',
      selection: {
        root: {
          fieldArguments: { __isPartialObject__: true },
          fieldQuery: {
            Root: {
              type: 'InlineFragment',
              objectTypeName: 'Root',
              selection: {
                displayNoCallsMotorBannerContent: {
                  fieldArguments: {},
                  fieldQuery: null,
                },
                enableMotorEmergencyBanner: {
                  fieldArguments: {},
                  fieldQuery: null,
                },
                enableHECEmergencyBanner: {
                  fieldArguments: {},
                  fieldQuery: null,
                },
                tempIssuesBanner: {
                  fieldArguments: {},
                  fieldQuery: {
                    TempIssuesBanner: {
                      type: 'FragmentSpread',
                      fragmentName: 'TempIssuesBanner',
                    },
                  },
                },
                enableTempIssuesBanner: {
                  fieldArguments: {},
                  fieldQuery: null,
                },
              },
            },
          },
        },
      },
    },
  },
};

export const initData = {
  commitId: 25038,
  hash: '7966737295195655',
  reducedExpression: {
    id: 'kSYXv9tNyvCbUC-8hF7KQ',
    logs: { events: {}, exposures: {}, evaluations: {} },
    type: 'ObjectExpression',
    fields: {
      root: {
        id: 'lXopPaSutQPq3FbOTLCkk',
        body: {
          id: '-tltczUlMlYnQEnsjJ1fl',
          logs: { events: {}, exposures: {}, evaluations: {} },
          type: 'ObjectExpression',
          fields: {
            displayNoCallsMotorBannerContent: {
              id: 'Xqt3w0FqGkoKOYAVfzia1',
              type: 'SwitchExpression',
              cases: [
                {
                  id: 'iYYczIrqWdF-IRQaOa-sy',
                  when: {
                    a: {
                      id: 'I7r52yoicc7qEiztG8HjG',
                      type: 'GetFieldExpression',
                      object: {
                        id: 'gZRUtoLmQgQ1tsDjXhry0',
                        type: 'VariableExpression',
                        valueType: {
                          type: 'ObjectValueType',
                          objectTypeName: 'Query_root_args',
                        },
                        variableId: 'Nx-90Ae3zuIpy3OGkOaWk',
                      },
                      fieldPath: 'context > environment',
                      valueType: {
                        type: 'EnumValueType',
                        enumTypeName: 'Environment',
                      },
                    },
                    b: {
                      id: 'dkLtHi9MbSOlIVg_ySE2s',
                      type: 'ListExpression',
                      items: [
                        {
                          id: '4BYWUd0oYF2qZgpW44DXn',
                          type: 'EnumExpression',
                          value: 'LOCAL',
                          valueType: {
                            type: 'EnumValueType',
                            enumTypeName: 'Environment',
                          },
                        },
                        {
                          id: 'TGB6OqNJcWltqLQzc0Kfh',
                          type: 'EnumExpression',
                          value: 'UAT',
                          valueType: {
                            type: 'EnumValueType',
                            enumTypeName: 'Environment',
                          },
                        },
                      ],
                      valueType: {
                        type: 'ListValueType',
                        itemValueType: {
                          type: 'EnumValueType',
                          enumTypeName: 'Environment',
                        },
                      },
                    },
                    id: 'J93rYxqmPVfwGmhRliTo4',
                    type: 'ComparisonExpression',
                    operator: 'in',
                    valueType: { type: 'BooleanValueType' },
                  },
                  then: {
                    id: 'Yr9uQd2qNs-svJXHexK8b',
                    type: 'BooleanExpression',
                    value: false,
                    valueType: { type: 'BooleanValueType' },
                  },
                },
              ],
              control: {
                id: 'yHDe0z4YHyp11zJwgTSYE',
                type: 'BooleanExpression',
                value: true,
                valueType: { type: 'BooleanValueType' },
              },
              default: {
                id: 'kYRO9mgfVjCuU64W7LktJ',
                type: 'BooleanExpression',
                value: false,
                valueType: { type: 'BooleanValueType' },
              },
              valueType: { type: 'BooleanValueType' },
              logs: { evaluations: { q4IWVOOh2d7ZxvyqhjZ80: 1 } },
            },
            enableMotorEmergencyBanner: {
              id: 'BY2Uw1akfqINzJ8A5imtL',
              type: 'SwitchExpression',
              cases: [
                {
                  id: 'wJBGC7LUgYGb1Ga1F4rT0',
                  when: {
                    a: {
                      id: 'xCCvFaqUodiF4FffbC7aZ',
                      type: 'GetFieldExpression',
                      object: {
                        id: '_XiKqRrCc2DgFJFr3MZIj',
                        type: 'VariableExpression',
                        valueType: {
                          type: 'ObjectValueType',
                          objectTypeName: 'Query_root_args',
                        },
                        variableId: 'Nx-90Ae3zuIpy3OGkOaWk',
                      },
                      fieldPath: 'context > environment',
                      valueType: {
                        type: 'EnumValueType',
                        enumTypeName: 'Environment',
                      },
                    },
                    b: {
                      id: 'jhBcLOca4q7Quo6D4F3RW',
                      type: 'ListExpression',
                      items: [
                        {
                          id: '83tDKIDCwnmW0trq05wio',
                          type: 'EnumExpression',
                          value: 'LOCAL',
                          valueType: {
                            type: 'EnumValueType',
                            enumTypeName: 'Environment',
                          },
                        },
                        {
                          id: '4nYmhzMTk6-B8SYv-kSB5',
                          type: 'EnumExpression',
                          value: 'UAT',
                          valueType: {
                            type: 'EnumValueType',
                            enumTypeName: 'Environment',
                          },
                        },
                      ],
                      valueType: {
                        type: 'ListValueType',
                        itemValueType: {
                          type: 'EnumValueType',
                          enumTypeName: 'Environment',
                        },
                      },
                    },
                    id: 'qlnWsMb5qGBa10427lfqm',
                    type: 'ComparisonExpression',
                    operator: 'in',
                    valueType: { type: 'BooleanValueType' },
                  },
                  then: {
                    id: 'EDllFXazckWO_3YrThh8p',
                    type: 'BooleanExpression',
                    value: false,
                    valueType: { type: 'BooleanValueType' },
                  },
                },
              ],
              control: {
                id: 'BZUfZ0D5fuxrHS-ZN1BGl',
                type: 'BooleanExpression',
                value: true,
                valueType: { type: 'BooleanValueType' },
              },
              default: {
                id: 'hqyFCFKl23UlwuE2MP2tA',
                type: 'BooleanExpression',
                value: false,
                valueType: { type: 'BooleanValueType' },
              },
              valueType: { type: 'BooleanValueType' },
              logs: { evaluations: { CNlnYfqEf1LIASYQuctpB: 1 } },
            },
            enableHECEmergencyBanner: {
              id: '_VPv90e7iy709QZ0mG1_f',
              type: 'SwitchExpression',
              cases: [
                {
                  id: '73r2_PeQB-dhrKjAuJ8kv',
                  when: {
                    a: {
                      id: 'SgVzUt8qIvWpWaVfjUZ1z',
                      type: 'GetFieldExpression',
                      object: {
                        id: 'aKt4_Roi2u-eL7cnQkGB1',
                        type: 'VariableExpression',
                        valueType: {
                          type: 'ObjectValueType',
                          objectTypeName: 'Query_root_args',
                        },
                        variableId: 'Nx-90Ae3zuIpy3OGkOaWk',
                      },
                      fieldPath: 'context > environment',
                      valueType: {
                        type: 'EnumValueType',
                        enumTypeName: 'Environment',
                      },
                    },
                    b: {
                      id: 'NHCzL9va0-nubsOUncrrn',
                      type: 'ListExpression',
                      items: [
                        {
                          id: 'TC0E7p7-ckHtpgqPicVza',
                          type: 'EnumExpression',
                          value: 'LOCAL',
                          valueType: {
                            type: 'EnumValueType',
                            enumTypeName: 'Environment',
                          },
                        },
                        {
                          id: 'R6DticDqalljdR7wTI5OR',
                          type: 'EnumExpression',
                          value: 'UAT',
                          valueType: {
                            type: 'EnumValueType',
                            enumTypeName: 'Environment',
                          },
                        },
                      ],
                      valueType: {
                        type: 'ListValueType',
                        itemValueType: {
                          type: 'EnumValueType',
                          enumTypeName: 'Environment',
                        },
                      },
                    },
                    id: 'TYqjGwIjqiYPq8s8LTZpp',
                    type: 'ComparisonExpression',
                    operator: 'in',
                    valueType: { type: 'BooleanValueType' },
                  },
                  then: {
                    id: '3ekss3--0e8abKl92Yulu',
                    type: 'BooleanExpression',
                    value: false,
                    valueType: { type: 'BooleanValueType' },
                  },
                },
              ],
              control: {
                id: 'NEvvii3u2Ha2S-xMo2SVv',
                type: 'BooleanExpression',
                value: true,
                valueType: { type: 'BooleanValueType' },
              },
              default: {
                id: '6ihdCleDqe9z9S0Uuvns2',
                type: 'BooleanExpression',
                value: false,
                valueType: { type: 'BooleanValueType' },
              },
              valueType: { type: 'BooleanValueType' },
              logs: { evaluations: { 'aOaOacMhfrCVQi-N9LVyW': 1 } },
            },
            tempIssuesBanner: {
              id: '_fUBlBVmSeXcy8TUKxEei',
              logs: { evaluations: { WgvuatBmYH9OOfu76d2WB: 1 } },
              type: 'ObjectExpression',
              fields: {
                isEnabled: {
                  id: 'vZ0AFxDXjqokZ7SQFSEOV',
                  logs: { evaluations: { '3o45AO83NJjChEjHulv_N': 1 } },
                  type: 'BooleanExpression',
                  value: false,
                  valueType: { type: 'BooleanValueType' },
                },
                messageToShow: {
                  id: '3aaXd8fcYrn0IteAlLjzs',
                  logs: { evaluations: { CN0Kt9D9e79QnuDAKiM_b: 1 } },
                  type: 'StringExpression',
                  value: '',
                  valueType: { type: 'StringValueType' },
                },
                displayUrls: {
                  id: 'fTCZhrTlay20ICz25TwGG',
                  logs: { evaluations: { ixuW7QKZFDLveqp53WXCJ: 1 } },
                  type: 'ListExpression',
                  items: [],
                  valueType: {
                    type: 'ListValueType',
                    itemValueType: { type: 'StringValueType' },
                  },
                },
              },
              valueType: {
                type: 'ObjectValueType',
                objectTypeName: 'TempIssuesBanner',
              },
              objectTypeName: 'TempIssuesBanner',
            },
            enableTempIssuesBanner: {
              id: 'K2M-rACn5chQefeng2Fzh',
              logs: { evaluations: { oPvoV_8o0AYoVUBLbZrYp: 1 } },
              type: 'BooleanExpression',
              value: false,
              valueType: { type: 'BooleanValueType' },
            },
          },
          valueType: { type: 'ObjectValueType', objectTypeName: 'Root' },
          objectTypeName: 'Root',
        },
        logs: { events: {}, exposures: {}, evaluations: {} },
        type: 'FunctionExpression',
        valueType: {
          type: 'FunctionValueType',
          returnValueType: { type: 'ObjectValueType', objectTypeName: 'Root' },
          parameterValueTypes: [
            { type: 'ObjectValueType', objectTypeName: 'Query_root_args' },
          ],
        },
        parameters: [{ id: 'Nx-90Ae3zuIpy3OGkOaWk', name: 'rootArgs' }],
      },
    },
    metadata: {
      permissions: { user: {}, group: { team: { write: 'allow' } } },
    },
    valueType: { type: 'ObjectValueType', objectTypeName: 'Query' },
    objectTypeName: 'Query',
  },
  splits: {},
  commitConfig: { splitConfig: {} },
};

/**
 * @deprecated use '@vercel/flags/providers/hypertune' package instead.
 */
export const vercelFlagDefinitions = {
  displayNoCallsMotorBannerContent: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EdisplayNoCallsMotorBannerContent',
  },
  enableMotorEmergencyBanner: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenableMotorEmergencyBanner',
  },
  enableHECEmergencyBanner: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenableHECEmergencyBanner',
  },
  'tempIssuesBanner.isEnabled': {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EtempIssuesBanner%3EisEnabled',
  },
  'tempIssuesBanner.messageToShow': {
    options: [],
    origin:
      'https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EtempIssuesBanner%3EmessageToShow',
  },
  enableTempIssuesBanner: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenableTempIssuesBanner',
  },
};

export type FlagValues = {
  displayNoCallsMotorBannerContent: boolean;
  enableMotorEmergencyBanner: boolean;
  enableHECEmergencyBanner: boolean;
  'tempIssuesBanner.isEnabled': boolean;
  'tempIssuesBanner.messageToShow': string;
  enableTempIssuesBanner: boolean;
};

export type FlagPaths = keyof FlagValues & string;

export const flagFallbacks: FlagValues = {
  displayNoCallsMotorBannerContent: false,
  enableMotorEmergencyBanner: false,
  enableHECEmergencyBanner: false,
  'tempIssuesBanner.isEnabled': false,
  'tempIssuesBanner.messageToShow': '',
  enableTempIssuesBanner: false,
};

export function decodeFlagValues<TFlagPaths extends keyof FlagValues & string>(
  encodedValues: string,
  flagPaths: TFlagPaths[]
): Pick<FlagValues, TFlagPaths> {
  return sdk.decodeFlagValues({ flagPaths, encodedValues });
}

export type VariableValues = {};

export const EnvironmentEnumValues = ['LOCAL', 'UAT', 'LIVE'] as const;
export type Environment = (typeof EnvironmentEnumValues)[number];

export type Context = {
  environment: Environment;
  currentTimestampMS: number;
};

export type RootArgs = {
  context: Context;
};

export type EmptyObject = {};

export type TempIssuesBanner = {
  isEnabled: boolean;
  messageToShow: string;
  displayUrls: string[];
};

const tempIssuesBannerFallback = {
  isEnabled: false,
  messageToShow: '',
  displayUrls: [],
};

export class TempIssuesBannerNode extends sdk.Node {
  override typeName = 'TempIssuesBanner' as const;

  get({
    fallback = tempIssuesBannerFallback as TempIssuesBanner,
  }: { fallback?: TempIssuesBanner } = {}): TempIssuesBanner {
    const getQuery = null;
    return this.getValue({ query: getQuery, fallback }) as TempIssuesBanner;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EtempIssuesBanner%3EisEnabled})
   */
  isEnabled({
    args = {},
    fallback,
  }: {
    args?: EmptyObject;
    fallback: boolean;
  }): boolean {
    const props0 = this.getFieldNodeProps('isEnabled', {
      fieldArguments: args,
    });
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EtempIssuesBanner%3EmessageToShow})
   */
  messageToShow({
    args = {},
    fallback,
  }: {
    args?: EmptyObject;
    fallback: string;
  }): string {
    const props0 = this.getFieldNodeProps('messageToShow', {
      fieldArguments: args,
    });
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EtempIssuesBanner%3EdisplayUrls})
   */
  displayUrls({
    args = {},
    itemFallback: fallback,
    listFallbackLength = 0,
  }: {
    args?: EmptyObject;
    itemFallback: string;
    listFallbackLength?: number;
  }): string[] {
    const props0 = this.getFieldNodeProps('displayUrls', {
      fieldArguments: args,
    });

    return new sdk.Node(props0)
      .getItemNodeProps({ fallbackLength: listFallbackLength })
      .map((props1) => {
        const expression1 = props1.expression;

        if (expression1 && expression1.type === 'StringExpression') {
          const node = new sdk.StringNode(props1);
          return node.get({ fallback });
        }

        const node = new sdk.StringNode(props1);
        node._logUnexpectedTypeError();
        return node.get({ fallback });
      });
  }
}

export type Root = {
  displayNoCallsMotorBannerContent: boolean;
  enableMotorEmergencyBanner: boolean;
  enableHECEmergencyBanner: boolean;
  tempIssuesBanner: TempIssuesBanner;
  enableTempIssuesBanner: boolean;
};

const rootFallback = {
  displayNoCallsMotorBannerContent: false,
  enableMotorEmergencyBanner: false,
  enableHECEmergencyBanner: false,
  tempIssuesBanner: { isEnabled: false, messageToShow: '', displayUrls: [] },
  enableTempIssuesBanner: false,
};

export class RootNode extends sdk.Node {
  override typeName = 'Root' as const;

  getRootArgs(): RootArgs {
    const { step } = this.props;
    return (
      step?.type === 'GetFieldStep' ? step.fieldArguments : {}
    ) as RootArgs;
  }

  get({ fallback = rootFallback as Root }: { fallback?: Root } = {}): Root {
    const getQuery = null;
    return this.getValue({ query: getQuery, fallback }) as Root;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EdisplayNoCallsMotorBannerContent})
   */
  displayNoCallsMotorBannerContent({
    args = {},
    fallback,
  }: {
    args?: EmptyObject;
    fallback: boolean;
  }): boolean {
    const props0 = this.getFieldNodeProps('displayNoCallsMotorBannerContent', {
      fieldArguments: args,
    });
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenableMotorEmergencyBanner})
   */
  enableMotorEmergencyBanner({
    args = {},
    fallback,
  }: {
    args?: EmptyObject;
    fallback: boolean;
  }): boolean {
    const props0 = this.getFieldNodeProps('enableMotorEmergencyBanner', {
      fieldArguments: args,
    });
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenableHECEmergencyBanner})
   */
  enableHECEmergencyBanner({
    args = {},
    fallback,
  }: {
    args?: EmptyObject;
    fallback: boolean;
  }): boolean {
    const props0 = this.getFieldNodeProps('enableHECEmergencyBanner', {
      fieldArguments: args,
    });
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EtempIssuesBanner})
   */
  tempIssuesBanner({
    args = {},
  }: { args?: EmptyObject } = {}): TempIssuesBannerNode {
    const props0 = this.getFieldNodeProps('tempIssuesBanner', {
      fieldArguments: args,
    });
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'TempIssuesBanner'
    ) {
      return new TempIssuesBannerNode(props0);
    }

    const node = new TempIssuesBannerNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenableTempIssuesBanner})
   */
  enableTempIssuesBanner({
    args = {},
    fallback,
  }: {
    args?: EmptyObject;
    fallback: boolean;
  }): boolean {
    const props0 = this.getFieldNodeProps('enableTempIssuesBanner', {
      fieldArguments: args,
    });
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }
}

/**
 * Welcome to Hypertune, the most powerful feature flag, A/B testing and app
 * configuration platform.
 *
 * Follow the quickstart: https://docs.hypertune.com/quickstart
 *
 * This is your schema, written in GraphQL. Use Boolean for feature flags,
 * custom enums for flags with more than two states, Int for numeric flags like
 * limits and timeouts, Strings for in-app copy, and custom object and list types
 * for more complex app configuration.
 *
 * Once you've defined your schema, head to the Logic tab.
 */
export type Source = {
  /**
   * You can add arguments to any field in your schema, which you can then
   * reference when defining your logic. We've added a 'context' argument on your
   * root field already, which contains details of the current 'user'.
   */
  root: Root;
};

const sourceFallback = {
  root: {
    displayNoCallsMotorBannerContent: false,
    enableMotorEmergencyBanner: false,
    enableHECEmergencyBanner: false,
    tempIssuesBanner: { isEnabled: false, messageToShow: '', displayUrls: [] },
    enableTempIssuesBanner: false,
  },
};

export type GetQueryRootArgs = {
  args: RootArgs;
};

export type GetQueryArgs = {
  root: GetQueryRootArgs;
};

/**
 * Welcome to Hypertune, the most powerful feature flag, A/B testing and app
 * configuration platform.
 *
 * Follow the quickstart: https://docs.hypertune.com/quickstart
 *
 * This is your schema, written in GraphQL. Use Boolean for feature flags,
 * custom enums for flags with more than two states, Int for numeric flags like
 * limits and timeouts, Strings for in-app copy, and custom object and list types
 * for more complex app configuration.
 *
 * Once you've defined your schema, head to the Logic tab.
 */
export class SourceNode extends sdk.Node {
  override typeName = 'Query' as const;

  get({
    args,
    fallback = sourceFallback as Source,
  }: {
    args: GetQueryArgs;
    fallback?: Source;
  }): Source {
    const getQuery = sdk.mergeFieldQueryAndArgs(
      query.fragmentDefinitions,
      sdk.getFieldQueryForPath(query.fragmentDefinitions, query.fieldQuery, []),
      args
    );
    return this.getValue({ query: getQuery, fallback }) as Source;
  }

  /**
   * You can add arguments to any field in your schema, which you can then
   * reference when defining your logic. We've added a 'context' argument on your
   * root field already, which contains details of the current 'user'.
   */
  root({ args }: { args: RootArgs }): RootNode {
    const props0 = this.getFieldNodeProps('root', { fieldArguments: args });
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'Root'
    ) {
      return new RootNode(props0);
    }

    const node = new RootNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }
}

export type DehydratedState = sdk.DehydratedState<Source, VariableValues>;
export type CreateSourceOptions = {
  token: string;
  variableValues?: VariableValues;
  override?: sdk.DeepPartial<Source> | null;
} & sdk.CreateOptions;

export function createSource({
  token,
  variableValues = {},
  override,
  ...options
}: CreateSourceOptions): SourceNode {
  return sdk.create({
    NodeConstructor: SourceNode,
    token,
    query,
    queryId,
    variableValues,
    override,
    options: { initData: initData as unknown as sdk.InitData, ...options },
  });
}

export const emptySource = new SourceNode({
  context: null,
  logger: null,
  parent: null,
  step: null,
  expression: null,
  initDataHash: null,
});

/**
 * @deprecated use createSource instead.
 */
export const initHypertune = createSource;
/**
 * @deprecated use SourceNode instead.
 */
export type QueryNode = SourceNode;
/**
 * @deprecated use Source instead.
 */
export type Query = Source;
