import { useHypertune } from '../generated/hypertune.react';

const useTempIssuesBanner = () => {
  const hypertune = useHypertune();

  return (
    hypertune
      .tempIssuesBanner()
      // @ts-ignore
      .get({ displayUrls: [], isEnabled: false, messageToShow: '' })
  );
};

export default useTempIssuesBanner;
